<template>
  <BModal
    v-model="visible"
    no-stacking
    centered
    hide-footer
    hide-header
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    size="md"
    body-class="p-2"
  >
    <div class="flex flex-col gap-4">
      <BCol class="flex justify-center">
        <BImg
          :src="
            modalType === 'success'
              ? 'https://storage.googleapis.com/komerce/core/icon-success-verified.svg'
              : 'https://storage.googleapis.com/komerce/assets/icons/warning.svg'
          "
          width="100"
        />
      </BCol>
      <div class="text-black font-semibold text-2xl text-center">
        {{ title }}
      </div>
      <div class="text-[#626262] text-center">
        {{ desc }}
      </div>
      <BRow class="justify-center">
        <BCol cols="6">
          <BButton
            variant="outline-primary"
            block
            size="md"
            @click="$emit('close')"
          >
            {{ cancelButtonText }}
          </BButton>
        </BCol>
        <BCol cols="6">
          <BButton
            block
            variant="primary"
            size="md"
            :disabled="isLoading"
            @click="$emit('oke')"
          >
            <BSpinner
              v-if="isLoading"
              variant="light"
              small
            />
            {{ okeButtonText }}
          </BButton>
        </BCol>
      </BRow>
    </div>
  </BModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    desc: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: null,
    },
    okeButtonText: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
