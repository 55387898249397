<template>
  <b-modal
    id="modal-extends-subcription"
    hide-header
    hide-footer
    no-close-on-backdrop
    modal-class="modal-primary"
    centered
    title="Primary Modal"
  >
    <div class="p-2 text-black">
      <h3 class="text-center text-[21px] text-black font-[600] mb-2">
        Pusat Berlangganan
      </h3>
      <div>
        <div class="text-[16px] mb-1 font-[600]">
          Fitur
        </div>
        <v-select
          v-model="valuePackage"
          class="mb-1"
          :reduce="item => item.value"
          disabled
          :options="listProduct"
          :clearable="false"
          label="name"
        />
      </div>
      <div>
        <div class="text-[16px] mb-1 font-[600]">
          Pilih Berlangganan
        </div>
        <v-select
          v-model="subscription"
          class="mb-1"
          :options="listSubscription"
          label="name"
          :clearable="false"
        />
      </div>
      <div>
        Saldo Kompay kamu
        <span class="text-primary">{{ saldo_kompay | rupiah }}</span>
      </div>
      <div class="justify-content-between d-flex mt-2">
        <div>
          <b-button
            variant="outline-primary"
            class="mr-1 w-[14rem] custom-btn"
            @click="$bvModal.hide('modal-extends-subcription')"
          >
            Batal
          </b-button>
        </div>
        <div>
          <b-button
            :variant="disabledSubcription() ? 'secondary' : 'primary'"
            class="ml-1 w-[14rem] custom-btn"
            :disabled="disabledSubcription()"
            @click="subscribe"
          >
            <b-spinner
              v-if="loadingSubcription"
              small
            />
            <span v-else>Bayar</span>
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import '@core/scss/vue/libs/vue-select.scss'
import vSelect from 'vue-select'
import { apiSubs } from '../service/api.whatsapp.services'

export default {
  components: {
    vSelect,
  },
  props: {
    curentPackage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      subscription: [],
      subsPlanlist: this.$store.state.komchat.subsPlanList,
      listProduct: [{
        name: 'Komchat Starter - Notifikasi WhatsApp Premium',
        value: 'starter',
      },
      {
        name: 'Komchat Basic - Broadcast',
        value: 'basic',
      },
      {
        name: 'Komchat Pro - Meta Official',
        value: 'pro',
      },
      ],
      valuePackage: [],
      listSubscription: [],
      saldo_kompay: this.$store.state.saldo.saldo,
      loadingSubcription: false,
    }
  },
  mounted() {
    this.$store.watch(
      state => state.saldo.saldo,
      (newValue, oldValue) => {
        this.saldo_kompay = newValue
      },
    )
    this.listSubscription = this.subsPlanlist.filter(item => item.category === this.curentPackage)
    this.valuePackage = this.listProduct.filter(item => item.value === this.curentPackage)
  },
  methods: {
    async subscribe() {
      try {
        this.loadingSubcription = true
        const { id } = this.subscription
        await apiSubs(id)
        this.$toast_success({ message: 'Berhasil melakukan pembayaran dan perpanjangan' })
        this.$bvModal.hide('modal-extends-subcription')
        this.loadingSubcription = false
        await this.$store.dispatch('whatsapp/getUpdateDashboardWa')
        await this.$store.dispatch('saldo/getBalanceSummary')
      } catch (error) {
        this.loadingSubcription = false
        this.$toast_error({ message: 'Gagal melakukan pembayaran dan perpanjangan' })
      }
    },
    disabledSubcription() {
      return this.subscription.nominal > this.saldo_kompay
    },
  },
}
</script>
