<template>
  <b-card class="container py-1 min-h-[80vh]">
    <h3 class="font-bold text-[24px] text-[#222222] mb-1">
      Aplikasiku
    </h3>
    <p class="font-normal text-[#626262] text-[14px] mb-1">
      Integrasikan antar layanan di Komerce dengan 1 akunmu agar saling terhubung dan membuka banyak manfaat fitur yang powerful
    </p>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BRow
        v-for="item in products"
        :id="`popover-${item.product_name}`"
        :key="item.product_name"
        class="mb-1 rounded-lg"
        style="box-shadow: 2px 4px 18px 0px rgba(0, 0, 0, 0.1)"
      >
        <BCol
          cols="12"
          class="d-flex justify-content-between py-2 pl-4 pr-2 mx-0"
        >
          <BImg
            :src="item.logo_url"
            width="120"
          />
          <button
            class="rounded-md px-[8px] py-[2px]"
            :class="getClassProduct(item.active, item.verified)"
            @click="getOnClickProduct(item)"
          >
            {{ getStatusProduct(item.active, item.verified) }}
          </button>
          <!-- <button
            v-else
            class="verif"
            @click="resendVerif(item.product_name)"
          >
            Kirim Ulang Verifikasi
          </button> -->
        </BCol>
        <BCol
          v-if="isSubscribe && item.product_name === 'komchat'"
          class="pb-1 pl-3 pr-1 mx-0 "
        >
          <Komchat :data-subscribe="dashboard_wa" />
        </BCol>
      </BRow>
    </BOverlay>
    <!-- <b-popover
        v-if="productList[1].active === false"
        target="popover-2"
        placement="topleft"
        triggers="hover"
      >
        <b>Integrasikan Akunmu dengan Kompack biar lebih Hemat!</b> Nikmati layanan pergudangan yang mudah dan murah mulai dari Rp3.000 tanpa biaya sewa bulanan.
        <a
          href="https://kompack.id/user-komship"
          target="_blank"
          style="color: #F95031 !important; text-decoration: underline !important;"
        >Klik di sini</a>
      </b-popover> -->

    <BOverlay
      v-if="product.is_komads.verified"
      :show="isLoadingCheckConnectionFacebook"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BCard
        class="border integrate mt-2"
        title="Integrasi"
        sub-title="Berikut merupakan media sosial akun yang tertaut dengan Komerce"
      >
        <BListGroup>
          <BListGroupItem class="!border-none !p-0 !flex justify-between">
            <div class="flex items-start gap-2">
              <BImg
                src="https://storage.googleapis.com/komerce/komads-meta/logo-facebook-primary.svg"
                width="30"
              />
              <div
                v-if="statusConnection.connect_facebook"
                class="flex flex-col"
              >
                <div class="text-black font-semibold">
                  {{ statusConnection.name }}
                </div>
                <div class="flex items-center gap-2">
                  <BImg src="https://storage.googleapis.com/komerce/assets/svg/Ellipsegreen.svg" />
                  <div class="text-[#34A770] text-sm">
                    Terhubung
                  </div>
                </div>
              </div>
              <div
                v-else
                class="font-semibold"
              >
                -
              </div>
            </div>
            <FacebookLoginButton
              v-if="!statusConnection.connect_facebook"
              :fields="'id,name,email,picture'"
              :scope="'public_profile,email'"
              :loading-props="isLoadingConnectFacebook"
              :status-connection="statusConnection.connect_facebook"
              :callback="handleCallbackFacebook"
            />
          </BListGroupItem>
        </BListGroup>
      </BCard>
    </BOverlay>
    <ModalNotification
      :visible="visibleModal"
      :type="'warning'"
      :title="'Putuskan akun Facebook'"
      :desc="'Kamu harus menghubungkan ulang akun facebookmu untuk menggunakan KomAds'"
      :cancel-button-text="'Batal'"
      :oke-button-text="'Putuskan'"
      :is-loading="isLoadingDisconnectFacebook"
      @close="visibleModal = false"
      @oke="onDisconnectFacebook"
    />

  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import FacebookLoginButton from '@/views/components/login-facebook/index.vue'
import { toast_error, toast_success } from '@/libs/toastification'
import { newAxiosIns } from '@/libs/axios'
// import { productList } from './config'
import Komchat from '@/views/pages/komship/myapps/Komchat.vue'
import { BCol } from 'bootstrap-vue'
import ModalNotification from './ModalNotification.vue'

export default {
  components: { FacebookLoginButton, ModalNotification, Komchat },
  data() {
    return {
      products: [],
      product: null,
      defaultWA: false,
      expiredDay: 0,
      badge: '',
      listProduct: [],
      loading: false,
      isLoadingConnectFacebook: false,
      isLoadingDisconnectFacebook: false,
      isLoadingCheckConnectionFacebook: false,
      statusConnection: {},
      visibleModal: false,
      isSubscribe: this.$store.state.whatsapp.dashboard_wa,
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
    ...mapState('whatsapp', ['check_wa', 'dashboard_wa']),
  },
  async mounted() {
    await this.fetchAplikasiku()
    await this.$store.dispatch('whatsapp/getUpdateCheckWa')
    await this.$store.dispatch('whatsapp/getUpdateDashboardWa')
    await this.$store.dispatch('saldo/getBalanceSummary')
    await this.checkWA()
  },
  methods: {
    async fetchAplikasiku() {
      this.loading = true
      try {
        const url = '/auth/api/v1/user/aplikasiku'
        const { data } = await newAxiosIns.get(url)
        const transformData = Object.entries(data.data).map(([key, value]) => ({
          product_name: key.replace('is_', ''),
          ...value,
        }))
        this.product = data.data
        this.products = transformData
        this.loading = false

        if (data.data.is_komads.active) {
          this.checkConnectionFacebook()
        }
      } catch (err) {
        this.loading = false
        toast_error({
          title: 'Error',
          message: 'Terjadi kesalahan saat mengambil data aplikasiku',
        })
      }
    },
    async checkWA() {
      this.defaultWA = this.check_wa.is_notification_whatsapp
      this.expiredDay = this.dashboard_wa !== null ? this.dashboard_wa.whatsapp_expired_at : 0
      this.badge = this.check_wa.is_user_type
      if (this.expiredDay === 0) {
        this.defaultWA = 0
      }
    },
    handleRenewWA() {
      if (this.expiredDay > 0) {
        this.$router.push({ name: 'dashboard-whatsapp', params: { modal: 'renew' } })
      } else {
        this.$router.push({ name: 'opsional-feature', params: { modal: 'renew' } })
      }
    },
    resendVerif(productName) {
      const params = {
        email: this.profile.user_email,
        product_name: productName,
      }
      this.$http_new.post('auth/api/v1/auth/resend-verification', params).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sukses',
            icon: 'CheckIcon',
            text: 'Kirim Ulang Verifikasi Berhasil',
            variant: 'success',
          },
        }, 2000)
        this.loading = false
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
        this.loading = false
      })
    },
    async checkConnectionFacebook() {
      this.isLoadingCheckConnectionFacebook = true
      const url = '/auth/api/v1/komads/status_connection'

      try {
        const { data } = await newAxiosIns.get(url)
        this.statusConnection = data.data
        this.isLoadingCheckConnectionFacebook = false
      } catch (error) {
        this.isLoadingCheckConnectionFacebook = false
        toast_error({
          title: 'Error',
          message: 'Terjadi kesalahan saat melakukan onboarding',
        })
      }
    },
    async onConnectFacebook(res) {
      const { status, authResponse, id } = res
      this.isLoadingConnectFacebook = true
      const url = '/auth/api/v1/komads/connect_account'

      if (status === 'connected') {
        const body = {
          access_token: authResponse.accessToken,
          fb_user_id: id,
        }

        try {
          await newAxiosIns.post(url, body)
          this.isLoadingConnectFacebook = false
          toast_success({
            title: 'Sukses Terhubung',
            message: 'Akun facebookmu telah terhubung',
          })
          this.$router.push('/iklan')
        } catch (error) {
          this.isLoadingConnectFacebook = false
          toast_error({
            title: 'Error',
            message: 'Gagal terhubung dengan Facebook',
          })
        }
      }
    },
    async onDisconnectFacebook() {
      this.isLoadingDisconnectFacebook = true
      const url = '/auth/api/v1/komads/logout'

      try {
        await newAxiosIns.delete(url)
        this.isLoadingDisconnectFacebook = false
        toast_success({
          title: 'Sukses Putuskan',
          message: 'Akun facebookmu telah terPutuskan',
        })
        this.checkConnectionFacebook()
        this.visibleModal = false
      } catch (error) {
        this.isLoadingDisconnectFacebook = false
        toast_error({
          title: 'Error',
          message: 'Disconnect Facebook gagal',
        })
      }
    },
    handleCallbackFacebook(res) {
      if (this.statusConnection.connect_facebook) {
        this.onDisconnectFacebook(res)
      } else {
        this.onConnectFacebook(res)
      }
    },
    getStatusProduct(active, verified) {
      if (!active && !verified) {
        return 'Pelajari selengkapnya'
      } if (active && verified) {
        return 'Terdaftar'
      } if (active && !verified) {
        return 'Kirim ulang verifikasi'
      }
      return ''
    },
    getClassProduct(active, verified) {
      if (!active && !verified) {
        return 'more'
      } if (active && verified) {
        return 'aktif'
      } if (active && !verified) {
        return 'verif'
      }
      return ''
    },
    getOnClickProduct(item) {
      if (!item.active && !item.verified) {
        window.open(item.deep_link, '_blank')
      } else if (item.active && !item.verified) {
        this.resendVerif(item.product_name)
      }
    },
    openWindow(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped src="./status.scss">
</style>
